import React, { useState } from "react";
import { useSelector } from "react-redux";

const OrderModal = ({ isOpen, onClose, onSubmit, loading }) => {
  const { user } = useSelector((state) => state.auth);

  const initialFormData = {
    productCategory: "",
    productName: "",
    productQuantity: "",
    productTotalPrice: "",
    instruction: "",
    productImage: "", // Store only image URL here
  };

  const [formData, setFormData] = useState(initialFormData);
  const [imagePreview, setImagePreview] = useState(null); // For previewing the selected image

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
      sellerId: user?._id,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        alert("File size must be under 2MB.");
        return;
      }
      if (!file.type.startsWith("image/")) {
        alert("Invalid file type. Please select an image.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Display image preview
        setFormData({ ...formData, productImage: reader.result }); // Save image URL (base64) to form data
      };
      reader.readAsDataURL(file); // Read the image file as a data URL
    }
  };

  const handleImageRemove = () => {
    setFormData({ ...formData, productImage: "" });
    setImagePreview(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSubmit = { ...formData };
    onSubmit(dataToSubmit); // Submit only the form data (productImage is already a URL)
    
    // Clear form fields after submission
    setFormData(initialFormData);
    setImagePreview(null); // Clear the image preview
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg w-[90%] sm:w-3/4 md:w-2/3 lg:w-1/2">
        <h2 className="text-lg font-bold mb-4 text-center">Submit New Order</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 md:grid-cols-2 gap-3">
            <input
              name="productTotalPrice"
              type="text"
              placeholder="Total Amount"
              value={formData.productTotalPrice}
              onChange={handleChange}
              className="border md:p-2 p-1 rounded w-full"
              required
            />

            <input
              name="productName"
              type="text"
              placeholder="Product Name"
              value={formData.productName}
              onChange={handleChange}
              className="border md:p-2 p-1 rounded w-full"
              required
            />
            <input
              name="productQuantity"
              type="text"
              placeholder="Product Quantity"
              value={formData.productQuantity}
              onChange={handleChange}
              className="border md:p-2 p-1 rounded w-full"
              required
            />
            <select
              name="productCategory"
              value={formData.productCategory}
              onChange={handleChange}
              className="border md:p-2 p-1 rounded w-full"
              required
            >
              <option value="">Select Category</option>
              <option value="Electronics">Electronics</option>
              <option value="Fashion">Fashion</option>
              <option value="Home">Home</option>
            </select>

            <textarea
              name="instruction"
              placeholder="Instruction"
              value={formData.instruction}
              onChange={handleChange}
              className="border md:p-2 p-1 rounded w-full col-span-1 md:col-span-2"
            ></textarea>

            <div className="col-span-2 flex flex-col items-center">
              {imagePreview ? (
                <div className="relative">
                  <img
                    src={imagePreview}
                    alt="Product Preview"
                    className="w-32 h-32 object-cover border rounded"
                  />
                  <button
                    type="button"
                    onClick={handleImageRemove}
                    className="absolute top-0 right-0 bg-red-500 text-white text-xs p-1 rounded-full"
                  >
                    ✕
                  </button>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <label
                    htmlFor="productImage"
                    className="bg-[#A95FB8] text-white px-4 py-2 rounded cursor-pointe"
                  >
                    Select Product Image
                  </label>
                  <input
                    id="productImage"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end mt-4 space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500"
              disabled={loading} // Disable button while loading
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#A95FB8] text-white px-4 py-2 rounded "
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <span>Loading...</span> // Show loading text
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OrderModal;

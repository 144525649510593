import React from 'react'
import SellerWorkingStatus from './sellerPages/SellerWorkingStatus'

const SellerSettings = () => {
  return (
    <div>
        <SellerWorkingStatus/>
    </div>
  )
}

export default SellerSettings